import React from 'react'
import Navbar from './Navbar'
import { GrGallery } from "react-icons/gr";
import g1 from '../img/projectimg/rohit.jpg'
import g2 from '../img/gallery/gp1.jpg'
import g3 from '../img/gallery/gp2.jpg'
import g4 from '../img/gallery/gp3.jpg'
import g5 from '../img/gallery/gp4.jpg'
import g6 from '../img/gallery/gp5.jpg'
import g7 from '../img/gallery/gp6.jpg'
import g8 from '../img/gallery/gp7.jpg'
import ga from '../img/projectimg/ga.jpg'
import roka from '../img/projectimg/roka.jpg'
import r1 from '../img/projectimg/r1.jpg'
import r2 from '../img/projectimg/r2.jpg'
import r3 from '../img/projectimg/r3.jpg'
import r4 from '../img/projectimg/r4.jpg'

const Gallery = () => {
    return (
        <>
            <Navbar />

            <div className='px-2 pt-24 text-white'>
                <h1 className='flex items-center justify-center gap-1 text-2xl text-orange-500' ><GrGallery />Gallery</h1>
                <div className='flex flex-col md:flex-row md:flex-wrap gap-4 items-center justify-center pb-16'>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Rohit Surfactant Pvt. Ltd. Bidar karnataka</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            <p>Owner Name- Gyanchandani/Mr. Murlidhar Star Tower GT Road Kanpur
                            </p>
                            <p>Value of work- 10 Cr
                                Construction Area- 6.5 Lac Sq Ft
                            </p></div>
                        <img className='' src={g1} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Rohit Surfactant Pvt. Ltd. Project Toilet Soap</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            <p>Owner Name-  Mr. Murlidhar Gyanchandani Star Tower GT Road Kanpur
                            </p>
                            <p>Value of work- 5Cr
                                Construction Area – 4.50 Lac Sq Ft (plot area)
                            </p></div>
                        <img className='' src={g2} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Rohit Surfactant Pvt. Ltd. Project Vinus Shampoo Plant</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            <p>Mr. Murlidhar Gyanchandani Star Tower GT Road Kanpur
                            </p>
                            <p>Value of work- 3.5 Cr
                                Construction Area- 2.40 Lac Sq Ft (Plot area)
                            </p></div>
                        <img className='' src={g3} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Sunehari Industries Pvt. Ltd</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            <p>Owner - Mr. Sumit Nanda New Delhi
                            </p>
                            <p>Value of work- 2 Cr
                                Construction Area- 1.5 Lac Sq Ft (Plot area)
                            </p></div>
                        <img className='' src={g4} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>GBL Pvt. Ltd</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            <p>Owner Name-  Mr. Vinay Kurele Noida (U.P.)
                            </p>
                            <p>Value of work- 3.5 Cr
                                Construction Area- 2.90 Lac Sq Ft (Plot area)
                            </p></div>
                        <img className='' src={g5} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Winder Fabromate Pvt. Ltd.</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            <p>Owner Name- Mr. Arun Kataruka New Delhi
                            </p>
                            <p>Value of work- 2.5 Cr
                                Construction Area- 75000 Sq Ft (Plot area)
                            </p></div>
                        <img className='' src={g6} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Ghari Detergent & Cake Dev Bhoomi</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            <p>Owner Name-Mr. Murlidhar Gyanchandani
                            </p>
                            <p>Value of work- 4 Cr
                                Construction Area- 3.5 Lac Sq Ft (Plot area)
                            </p></div>
                        <img className='' src={g7} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Rohit Surfactant Pvt. Ltd. Project Diaper Unit</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            <p>Owner Name-Mr. Murlidhar Gyanchandani
                            </p>
                            <p>Value of work- 7 Cr
                                Construction Area- 2.28 Lac Sq Ft ( Plot Area)
                            </p></div>
                        <img className='' src={g8} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Ghari Detergent & Cake Dev Bhoomi</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            <p>Owner Name-Mr. Murlidhar Gyanchandani 
                            </p>
                            <p>Value of work- 4 Cr
                            Construction Area- 3.5 Lac Sq Ft (Plot area)
                            </p></div>
                        <img className='' src={ga} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Rohit Surfactant Pvt. Ltd. Project Diaper Unit</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            <p>Owner Name-Mr. Murlidhar Gyanchandani 
                            </p>
                            <p>Value of work- 7 Cr
                            Construction Area- 2.28 Lac Sq Ft ( Plot Area)
                            </p></div>
                        <img className='' src={roka} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Raghunath mall & residency (Construction Area- 7 Lac sq ft)</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            {/* <p>Owner Name-Mr. Murlidhar Gyanchandani 
                            </p>
                            <p>Value of work- 7 Cr
                            Construction Area- 2.28 Lac Sq Ft ( Plot Area)
                            </p>*/}</div> 
                        <img className='' src={r1} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Sky Bonglow in kanpur (Construction Area-9.30 Lac sq ft)</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            {/* <p>Owner Name-Mr. Murlidhar Gyanchandani 
                            </p>
                            <p>Value of work- 7 Cr
                            Construction Area- 2.28 Lac Sq Ft ( Plot Area)
                            </p>*/}</div> 
                        <img className='' src={r2} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>Hyatt hotel ( Seven Star) in dehradun (Construction Area- 4.75 Lac sq ft)</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            {/* <p>Owner Name-Mr. Murlidhar Gyanchandani 
                            </p>
                            <p>Value of work- 7 Cr
                            Construction Area- 2.28 Lac Sq Ft ( Plot Area)
                            </p>*/}</div> 
                        <img className='' src={r3} alt="" />
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500" className='hover:shadow-md hover:shadow-white mt-10 px-2 py-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  md:w-2/5 '>
                        <div className='text-center'><h1>KRS Park & Homes Haridwar (Construction Area- 1.5 Lac sq ft)</h1>
                            <p>Description of Work - All Constructive Work
                            </p>
                            {/* <p>Owner Name-Mr. Murlidhar Gyanchandani 
                            </p>
                            <p>Value of work- 7 Cr
                            Construction Area- 2.28 Lac Sq Ft ( Plot Area)
                            </p>*/}</div> 
                        <img className='' src={r4} alt="" />
                    </div>
                 

                </div>

                {/* <div className='flex justify-end pt-6'>
                    <button className='bg-orange-500 text-white py-1 px-3 rounded'>See More</button>
                </div> */}
            </div>

        </>
    )
}

export default Gallery