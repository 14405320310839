import React from 'react'
import { FaHome } from "react-icons/fa";
import about1 from '../img/image/about.jpg'
import about2 from '../img/image/about2.jpg'
import about3 from '../img/image/about3.jpg'

const About = () => {
    return (
        <>
            {/* About */}

            <div id='about' className="flex justify-between items-center lg:flex-row  flex-col pt-52 mb-20 text-white">
                <div className='aboutLeft w-full'>
                    <div data-aos="zoom-in-down" className=''>
                        <img className=' ml-24 h-56 -mt-40 md:h-64 absolute ' src={about3} alt="" />
                    </div>
                    <div data-aos="zoom-in-up">
                        <img className='ml-6 z-50 absolute h-72 md:h-96 ' src={about2} alt="" />
                    </div>
                    <div data-aos="zoom-in-right" className=''>
                        <img className='relative h-80 md:h-96' src={about1} alt="" />
                    </div>
                </div>

                <div className='aboutRight w-full lg:mt-0 mt-2 px-3'>
                    <span className='flex items-center  w-44 rounded px-2 text-2xl py-2 gap-1 text-orange-500 bg'>
                        <FaHome /> About Us
                    </span>
                    <h1 data-aos="fade-down" className='text-2xl'>We Provides Construction Services That Meet Your Needs.</h1>
                    <div className='mt-4'>

                        <h1 className='text-xl text-justify'><span className='text-orange-500'>Mr Manoj Tiwari</span> managing director of these
                            construction companies is also a B.E civil engineer.
                            They have completed so many construction projects
                            under their guidance and having a experience of
                            Around 20 year in construction</h1>

                        <div data-aos="fade-up"
                            data-aos-duration="3000" className='mt-2 '>
                            <h1 className='font-bold py-2'>Our Companies</h1>
                            <ul className='text-orange-500'>
                                <li>MSB INFRA PROJECTS PVT.LTD</li>
                                <li>ARYAAN CONSTRUCTION</li>
                                <li>BHUMI DEVELOPERS</li>
                            </ul>
                            <h1 className='font-bold py-2'>Specialization of company</h1>
                            <ul>
                                <li>OUR Company is one of the leading Construction Company in India. We are the constructer of Hyaat
                                    hotel rajpur road ,dehradun where we are building five star hotel also we are constructing red chief,
                                    namasty india, RSPL company, A sky high building in Kanpur.</li>
                                <li>We are the constructer of raghunath mall (which also included suncity multiplex, hotel ITC Fortune
                                    park ) & raghunath residency in Haridwar where we delivered more than 380 flats having construction
                                    area of 1 Lac Sq ft. Basically we are the constructer of five star hotel and residential project in all over
                                    India.</li>
                                <li>Company do construction projects in all over India which includes residential projects,

                                    Commercial projects & five star hotels .</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default About