import React from 'react'
import { FaHome } from "react-icons/fa";
import { MdOutlineDateRange } from "react-icons/md";
import b1 from '../img/blog/b1.jpg'

const Blog = () => {
  return (
    <>
    <div className='text-white'>
        <div>
            <div className='w-full'>
            <h1 className='flex items-center gap-1 justify-center text-orange-500 text-2xl py-4 '><FaHome /> Our Blog & News</h1>
            <span className='flex items-center  justify-center' >Get More Updates For News & Articles</span>
            </div>
        </div>

        <div className='mx-3 mt-9 flex justify-center flex-col md:flex-row md:flex-wrap gap-3'>
            <div data-aos="fade-up"
                    data-aos-duration="3000"
             className='hover:shadow-md hover:shadow-white px-5 py-5 shadow-[rgba(0,_0,0,_1.24)_0px_3px_8px] md:w-[30%]'>
                <div className='flex items-center gap-2'>
                    <img className='rounded-full' src={b1} alt="" />  
                    <span>Written By MSC</span>
                </div>
                <div className='pt-28 flex justify-between'>
                    <span className='bg-orange-500 text-white px-2 py-2 rounded'>Clock Fly Strategy</span>
                    <span className='flex items-center gap-1'><MdOutlineDateRange /> 10 July,2024</span>
                </div>
             <p className='pt-6'>How to Plan and Execute a Successful Construction Projects</p>
                <button className='mt-20 bg-orange-500 text-white py-1 px-3 rounded' >Read More</button>
            </div>
            <div data-aos="fade-up"
                    data-aos-duration="3000"
             className='hover:shadow-md hover:shadow-white px-5 py-5 shadow-[rgba(0,_0,0,_1.24)_0px_3px_8px] md:w-[30%]'>
                <div className='flex items-center gap-2'>
                    <img className='rounded-full' src={b1} alt="" />  
                    <span>Written By MSC</span>
                </div>
                <div className='pt-28 flex justify-between'>
                    <span className='bg-orange-500 text-white px-2 py-2 rounded'>Clock Fly Strategy</span>
                    <span className='flex items-center gap-1'><MdOutlineDateRange /> 10 July,2024</span>
                </div>
             <p className='pt-6'>How to Plan and Execute a Successful Construction Projects</p>
                <button className= 'mt-20 bg-orange-500 text-white py-1 px-3 rounded' >Read More</button>
            </div>
            <div data-aos="fade-up"
                    data-aos-duration="3000"
            className='hover:shadow-md hover:shadow-white px-5 py-5 shadow-[rgba(0,_0,0,_1.24)_0px_3px_8px] md:w-[30%]'>
                <div className='flex items-center gap-2'>
                    <img className='rounded-full' src={b1} alt="" />  
                    <span>Written By MSC</span>
                </div>
                <div className='pt-28 flex justify-between'>
                    <span className='bg-orange-500 text-white px-2 py-2 rounded'>Clock Fly Strategy</span>
                    <span className='flex items-center gap-1'><MdOutlineDateRange /> 10 July,2024</span>
                </div>
             <p className='pt-6'>How to Plan and Execute a Successful Construction Projects</p>
                <button className= 'mt-20 bg-orange-500 text-white py-1 px-3 rounded' >Read More</button>
            </div>
        </div>

        <div className='flex justify-center mt-9 px-4'>
            <p>Want to see our update news & articles. <span className='text-orange-500 underline' >Click here to View More</span></p>
        </div>
    </div>
    </>
)
}

export default Blog