import React from 'react'
import { FaHome } from "react-icons/fa";
import ra from '../img/projectimg/ra.jpg'
import rohit from '../img/projectimg/rohit.jpg'
import rohit1 from '../img/projectimg/rohit1.jpg'
import rohit2 from '../img/projectimg/rohit2.jpg'
import sun from '../img/projectimg/sun.jpg'
import ga from '../img/projectimg/ga.jpg'
import { Link } from 'react-router-dom';

const Project = () => {
    return (
        <>
            <div className='mt-16 mb-16 pb-16 px-2'>
                <div className=' flex items-center justify-center mb-2 w-full '>
                    <span className='flex items-center justify-center gap-2 w-48   outline-none rounded-xl text-orange-600 font-bold text-xl py-4 px-3'><FaHome />Our Projects</span>
                </div>
                <div className='px-3 pb-7 text-center'>
                    <h1 className='text-white text-xl font-bold'>Explore Our Best Recently Delivered Projects</h1>
                </div>

                {/* carousel */}
                <div className='flex justify-center items-center md:flex-row md:flex-wrap flex-col gap-14 px-4'>

                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500"
                        className='md:h-[500px]   md:w-1/4 bg-white flex items-center justify-center flex-col text-black   px-3 py-3  shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]'>
                        <h1 className='text-justify font-bold'>Raghunath Mall & Residency </h1>
                        <p> <span className='font-bold' >Location</span> : Lotus Infra Projects Pvt. Ltd. NH-58 Bahadrabad Haridwar  <br />
                            <span className='font-bold' >Description of Work</span> - All Constructive Work <br />
                            <span className='font-bold' >Owner Name</span> -Mr. Om Prakash Agarwall <br />
                            Value of work- 25 Cr
                            Construction Area- 7 Lac sq ft

                        </p>
                        <div className=''>
                            <img src={ra} alt="" />
                        </div>
                    </div>

                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500"
                        className='md:h-[500px]  md:w-1/4 bg-white flex items-center justify-center flex-col text-black   px-3 py-3  shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]'>
                        <h1 className='text-justify font-bold'>Rohit Surfactant Pvt. Ltd</h1>
                        <p> <span className='font-bold' >Location</span> :Bidar karnataka <br />
                            <span className='font-bold' >Description of Work</span> - All Constructive Work <br />
                            <span className='font-bold' >Owner Name</span> -Gyanchandani/Mr. Murlidhar<br />
                            Star Tower GT Road
                            Kanpur
                            Value of work- 10 Cr
                            Construction Area- 6.5 Lac Sq Ft

                        </p>
                        <div className=''>
                            <img src={rohit} alt="" />
                        </div>
                    </div>

                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500"
                        className='md:h-[500px]  md:w-1/4 bg-white flex items-center justify-center flex-col text-black   px-3 py-3  shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]'>
                        <h1 className='text-justify font-bold'>Rohit Surfactant Pvt. Ltd. Project Red & Chief 	Leather &Foot wire Division</h1>
                        <p> <span className='font-bold' >Location</span> :Star Tower GT Road Kanpur<br />
                            <span className='font-bold' >Description of Work</span> - All Constructive Work <br />
                            <span className='font-bold' >Owner Name</span> -Mr. Murlidhar Gyanchandani<br />
                            Value of work- 3.5 Cr
                            Construction Area- 1.20 Lac Sq Ft

                        </p>
                        <div className=''>
                            <img src={rohit1} alt="" />
                        </div>
                    </div>

                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500"
                        className='md:h-[500px]  md:w-1/4 bg-white flex items-center justify-center flex-col text-black   px-3 py-3  shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]'>
                        <h1 className='text-justify font-bold'>Rohit Surfactant Pvt. Ltd. Project Vinus Shampoo  Plant</h1>
                        <p> <span className='font-bold' >Location</span> :Sec – 3 Plot No. -3A, 6, 7
                            <br />
                            <span className='font-bold' >Description of Work</span> - All Constructive Work <br />
                            <span className='font-bold' >Owner Name</span> -Mr. Murlidhar Gyanchandani Star Tower GT Road Kanpur<br />
                            Value of work- 5Cr
                            Construction Area- 1.10 Lac Sq Ft ( Plot Area)

                        </p>
                        <div className=''>
                            <img src={rohit2} alt="" />
                        </div>
                    </div>

                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500"
                        className='md:h-[500px]  md:w-1/4 bg-white flex items-center justify-center flex-col text-black   px-3 py-3  shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]'>
                        <h1 className='text-justify font-bold'>Sunehari Industries Pvt. Ltd</h1>
                        <p> <span className='font-bold' >Location</span> : Sec- 7 Sidcul Haridwar <br />
                            <span className='font-bold' >Description of Work</span> - All Constructive Work <br />
                            <span className='font-bold' >Owner Name</span> -  Mr. Sumit Nanda <br />
                            New Delhi
                            Value of work- 2 Cr
                            Construction Area- 1.5 Lac Sq Ft (Plot area)
                        </p>
                        <div className=''>
                            <img src={sun} alt="" />
                        </div>
                    </div>
                    <div data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500"
                        className='md:h-[500px]  md:w-1/4 bg-white flex items-center justify-center flex-col text-black   px-3 py-3  shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]'>
                        <h1 className='text-justify font-bold'>Ghari Detergent & Cake Dev Bhoomi</h1>
                        <p> <span className='font-bold' >Location</span> : Sagar  Madhya Pradesh <br />
                            <span className='font-bold' >Description of Work</span> - All Constructive Work <br />
                            <span className='font-bold' >Owner Name</span> -  Mr. Murlidhar Gyanchandani <br />
                            Value of work- 4 Cr
                            Construction Area- 3.5 Lac Sq Ft (Plot area)

                        </p>
                        <div className=''>
                            <img src={ga} alt="" />
                        </div>
                    </div>



                </div>
                <div className='flex justify-end mt-6'>
                    <Link to="/Projectadd"><button className='bg-orange-500 text-white py-1 px-3 rounded hover:bg-orange-600 hover:font-bold'>See More</button></Link>
                </div>
            </div>
        </>
    )
}

export default Project