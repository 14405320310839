import React from 'react'
import jcb from '../img/image/jcb.jpg'
import { MdOutlineEmail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import Navbar from './Navbar';
const Banner = () => {
  return (
    <>
    <Navbar/>
     {/* banner  */}
     <div className='pt-16 flex flex-col-reverse lg:flex-row items-center justify-evenly md:pt-28 gap-10'>
                
                {/* banner content */}
                <div data-aos="fade-down"
                    data-aos-duration="3000" className='w-full text-center' >
                    <h1 className=' md:text-4xl  text-white font-extrabold text-2xl '>WE PROVIDING HIGH QUALITY BULDING MATERIAL</h1>
                    <p className='text-white leading-none pt-6 px-1 text-justify text-sm'>At <span className='text-orange-500' >MAA SHARDA CONSTRUCTION</span>,we specialize in turning architectural visions into tangible reality. With a steadfast commitment to precision and quality, we undertake each project with unwavering dedication. From residential developments to commercial complexes, our team of experienced professionals ensures every detail is meticulously crafted. We pride ourselves on delivering innovative solutions that not only meet but exceed expectations. With a focus on sustainability and efficiency, we strive to create enduring structures that harmonize with their surroundings. Discover how we can bring your construction projects to life, with expertise that stands the test of time.</p>

                    <div className='text-white flex items-center justify-center flex-col mt-6 mx-4'>
                        <h1 className='text-orange-700 text-2xl border py-1 px-2 hover:bg-orange-600 cursor-pointer  hover:text-white'>Contact Us</h1>
                       
                        <div className='flex items-center justify-center gap-1 md:text-xl text-orange-500 flex-wrap hover:text-white'><MdOutlineEmail /> <a href="mailto:maasharda_construction@yahoo.in">maasharda_construction@yahoo.in</a></div>
                        <div className='flex items-center justify-center gap-2 text-xl text-orange-500 hover:text-white'><IoCallOutline /><a href="tel: 9411111161">+91 9411111161</a> </div>
                        <div className='flex items-center justify-center gap-2 text-xl text-orange-500 hover:text-white'><IoCallOutline /><a href="tel: 9536133777">+91 9536133777</a> </div>
                    </div>
                </div>
                <div data-aos="fade-up"
                    data-aos-duration="3000"
                className='w-full'>
                    <img className='' src={jcb} alt="banner" />
                </div>
            </div>
    </>
)
}

export default Banner