import React from 'react'
import { FaHome } from "react-icons/fa";
import s1 from '../img/service/s1.jpg'
import s2 from '../img/service/s2.jpg'
import s3 from '../img/service/s3.jpg'
import s4 from '../img/service/s4.jpg'
import s5 from '../img/service/s5.jpg'
import { FaArrowCircleLeft } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";


const Services = () => {
    return (
        <>
            <div className='ourServices px-2'>
                <div className='flex items-center text-orange-500 text-2xl py-3'>
                    <FaHome /> Our Best Services
                </div>

                <div data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500" className='text-white '>
                    <div className='leftSec text-white'>
                        <h1 className='text-2xl py-2 md:text-4xl font-bold'>We Provide Excellent Best Services</h1>
                        <p>At out company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible. We ensure satisfaction and success for every client.</p>
                    </div>
                    <div className='rightSec flex flex-col items-center py-6'>
                        {/* mobile view */}
                        <div className="md:hidden carousel gap-1  w-64">
                            <div className="carousel-item flex items-center flex-col  w-60 text-white border ">
                                <div>
                                    <h1 className='text-2xl'>Architecture Design</h1>
                                </div>
                                <img
                                    src={s1}
                                    className="w-48 h-48 object-contain "
                                    alt="Tailwind CSS Carousel component" />
                                <p className='px-1' >At our company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible</p>
                            </div>
                            <div className="carousel-item flex items-center flex-col  w-60 text-white border ">
                                <div>
                                    <h1 className='text-2xl'>Repair Services</h1>
                                </div>
                                <img
                                    src={s2}
                                    className="w-48 h-48 object-contain "
                                    alt="Tailwind CSS Carousel component" />
                                <p className='px-1' >At our company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible</p>
                            </div>
                            <div className="carousel-item flex items-center flex-col  w-60 text-white border ">
                                <div>
                                    <h1 className='text-2xl'>Building Constructions</h1>
                                </div>
                                <img
                                    src={s3}
                                    className="w-48 h-48 object-contain "
                                    alt="Tailwind CSS Carousel component" />
                                <p className='px-1' >At our company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible</p>
                            </div>
                            <div className="carousel-item flex items-center flex-col  w-60 text-white border ">
                                <div>
                                    <h1 className='text-2xl'>Building Renovation</h1>
                                </div>
                                <img
                                    src={s4}
                                    className="w-48 h-48 object-contain "
                                    alt="Tailwind CSS Carousel component" />
                                <p className='px-1' >At our company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible</p>
                            </div>
                            <div className="carousel-item flex items-center flex-col  w-60 text-white border ">
                                <div>
                                    <h1 className='text-2xl'>Interior Design</h1>
                                </div>
                                <img
                                    src={s5}
                                    className="w-48 h-48 object-contain "
                                    alt="Tailwind CSS Carousel component" />
                                <p className='px-1' >At our company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible</p>
                            </div>
                        </div>

                        {/* Medium device view */}

                        <div className="hidden md:inline-flex carousel rounded-box gap-2 w-full">
                            <div className="carousel-item flex items-center flex-col  w-1/3 text-white border ">
                                <div>
                                    <h1 className='text-2xl'>Interior Design</h1>
                                </div>
                                <img
                                    src={s5}
                                    className="w-full"
                                    alt="Tailwind CSS Carousel component" />
                                <p className='px-1' >At our company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible</p>
                            </div>
                            <div className="carousel-item flex items-center flex-col  w-1/3 text-white border ">
                                <div>
                                    <h1 className='text-2xl'>Architecture Design</h1>
                                </div>
                                <img
                                    src={s1}
                                    className="w-full"
                                    alt="Tailwind CSS Carousel component" />
                                <p className='px-1' >At our company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible</p>
                            </div>
                            <div className="carousel-item flex items-center flex-col  w-1/3 text-white border ">
                                <div>
                                    <h1 className='text-2xl'>Repair Services</h1>
                                </div>
                                <img
                                    src={s2}
                                    className="w-full "
                                    alt="Tailwind CSS Carousel component" />
                                <p className='px-1' >At our company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible</p>
                            </div>
                            <div className="carousel-item flex items-center flex-col  w-1/3 text-white border ">
                                <div>
                                    <h1 className='text-2xl'>Building Constructions</h1>
                                </div>
                                <img
                                    src={s3}
                                    className="w-full "
                                    alt="Tailwind CSS Carousel component" />
                                <p className='px-1' >At our company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible</p>
                            </div>
                            <div className="carousel-item flex items-center flex-col  w-1/3 text-white border ">
                                <div>
                                    <h1 className='text-2xl'>Building Renovation</h1>
                                </div>
                                <img
                                    src={s4}
                                    className="w-full"
                                    alt="Tailwind CSS Carousel component" />
                                <p className='px-1' >At our company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible</p>
                            </div>
                            <div className="carousel-item flex items-center flex-col  w-1/3 text-white border ">
                                <div>
                                    <h1 className='text-2xl'>Interior Design</h1>
                                </div>
                                <img
                                    src={s5}
                                    className="w-full "
                                    alt="Tailwind CSS Carousel component" />
                                <p className='px-1' >At our company, excellence is not just a goal; it's our standard. With a dedication to providing the best services possible</p>
                            </div>
                        </div>

                        {/* swipe righ left */}
                        <div className='text-orange-500 flex items-center py-3 gap-5'>
                            <FaArrowCircleLeft />Swipe<FaArrowCircleRight />
                        </div>

                        {/* more link */}
                        <div data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500" className='px-4 pt-3'>
                            Want to see our best services. <span className='text-orange-500' ><a href="">Click here to View More</a></span>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Services