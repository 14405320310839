import { IoMenu } from "react-icons/io5";
import { MdConstruction } from "react-icons/md";
import icon from '../img/image/icon.jpeg'

import { useState } from 'react';
import Career from "./Career";
import { Link } from "react-router-dom";
const Navbar = () => {
    const [hamburgerOpen, setHamburgerOpen] = useState(false)
     const toggleMenu=()=>{
        setHamburgerOpen(! hamburgerOpen)
     }
    return (
        <>
            <div className='fixed top-0 left-0 z-50 bg-[#222526] w-full px-3 md:px-7'>
                <div className='w-full   py-3 flex items-center justify-between text-white border-b-2'>
                    
                    <div className='flex items-center bg-orange-500 py-1 px-1 rounded'>
                        <img className="h-9 w-9 object-cover rounded-full" src={icon} alt="" /> Maa Sharda Construction
                    </div>
                    
                    <div className='hidden md:block text-white' >
                        <ul className="w-full flex  justify-between gap-5 ">
                            <li className='border px-4 py-2 border-orange-400 rounded hover:bg-orange-700 duration-300'><Link to="/">Home</Link></li>
                            <li className='border px-4 py-2 border-orange-400 rounded hover:bg-orange-700 duration-300'><Link to="/gallery">Gallery</Link></li>
                            {/* <li className='border px-4 py-2 border-orange-400 rounded hover:bg-orange-700 duration-300'><a href="#about">About</a></li> */}
                            <li className='border px-4 py-2 border-orange-400 rounded hover:bg-orange-700 duration-300'><Link to="/career">Career</Link></li>
                            <li className='border px-4 py-2 border-orange-400 rounded hover:bg-orange-700 duration-300'><Link to="/about">About Us</Link></li>
                            <li className='border px-4 py-2 border-orange-400 rounded hover:bg-orange-700 duration-300'><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>
                  
                    <div onClick={toggleMenu} className='md:hidden bg-orange-500 rounded w-9 h-9 text-center flex justify-center items-center text-xl px-1 py-1'>
                    <IoMenu className="w-full h-full" />
                    </div>
                </div>

                 {/* mobile manu */}
                 <div  className='mobmenu absolute top-0 w-full left-0 h-[60vh] pt-24 text-white  bg-gray-700 '>
                    
                    <ul  onClick={toggleMenu}  className=' text-center flex flex-col items-center justify-cente'>
                    <li onClick={toggleMenu} className=' text-red-600 py-2 px-3 hover:bg-orange-700' ><a href="#">Close</a></li>
                    <li className=' px-4 py-2 hover:bg-orange-700 duration-300'><Link to="/">Home</Link></li>
                            <li className=' px-4 py-2  hover:bg-orange-700 duration-300'><Link to="/gallery">Gallery</Link></li>
                            {/* <li className='border px-4 py-2 border-orange-400 rounded hover:bg-orange-700 duration-300'><a href="#about">About</a></li> */}
                            <li className=' px-4 py-2  hover:bg-orange-700 duration-300'><Link to="/career">Career</Link></li>
                            <li className=' px-4 py-2  hover:bg-orange-700 duration-300'><Link to="/about">About Us</Link></li>
                            <li className=' px-4 py-2  hover:bg-orange-700 duration-300'><Link to="/contact">Contact Us</Link></li>
                    </ul>
                    </div>
                
        <style >{`
            .mobmenu{
            display: ${hamburgerOpen? 'inline':'none'}
            
            }
        `}
        </style>

              {/* importing Banner component */}
               {/* <Banner/> */}
            </div>
        </>
    )
}

export default Navbar