import React from 'react'
// import probg from '../../public/image/project-bg.jpg'
import { FaHome } from "react-icons/fa";

// import { MdAddCall } from "react-icons/md";
import c1 from '../img/image/c1.jpg'
import c2 from '../img/image/c2.jpg'
import { MdOutlineEmail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";




const Contact = () => {
    return (
        <>
            <div id='contact' className="pt-24 pb-5">

                <div className='flex flex-col justify-center items-center gap-7 pb-6'>
                    <span className='flex items-center text-2xl justify-center font-bold gap-2 text-orange-500 bg-slate-200   py-2 rounded'><FaHome />Contact Us</span>
                </div>



                <div data-aos="fade-up"
                    data-aos-duration="3000"
                    className='flex flex-col md:flex-row md:justify-between md:gap-2 px-2 text-white bg-orange-500  mt-8'>
                    <div className='divLeft flex flex-col md:flex-row md:items-center justify-center items-center w-full pt-4 md:w-2/5'>
                        <div data-aos="fade-up"
                            data-aos-duration="2000" ><img className='pb-10 md:hidden' src={c2} alt="" /></div>

                        {/* form */}
                        <form action="mailto:jatinkaushik052@gmail.com" method="post" enctype="text/plain" className='w-full text-black'>
                            <div className='flex flex-col px-2'>
                                <label className='text-xl pb-1' htmlFor="">Full Name*</label>
                                <input className='h-11 px-3 text-xl bg-white' type="text" placeholder='Enter your name' name="" id="" />
                            </div>
                            <div className='flex flex-col px-2'>
                                <label className='text-xl pb-1' htmlFor="">Email*</label>
                                <input className='h-11 px-3 text-xl bg-white' type="text" placeholder='Enter your email' name="" id="" />
                            </div>
                            <div className='flex flex-col px-2'>
                                <label className='text-xl pb-1' htmlFor="">Message*</label>
                                <textarea className='text-xl px-3 bg-white' placeholder='Type your message..' name="" id="" cols="30" rows="5"></textarea>
                            </div>
                            <div className='px-2 py-2'>
                                <button className='bg-black py-2 px-4 hover:bg-orange-700 hover:font-bold text-white w-full' value='send'>Send Message Now</button>
                            </div>
                        </form>
                       
                    </div>

                    <div className='divRight flex items-center justify-center w-full md:w-2/5'>
                        <div>
                            <a href="https://maps.app.goo.gl/LD5Z1zVxt9QNyAyn7"> <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13832.331863071555!2d78.0409416!3d29.9195136!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39094900777227f5%3A0xb82073b2498c0afd!2sKRS%20PARK%20AND%20HOMES%2C%20BAHADARABAD!5e0!3m2!1sen!2sin!4v1722017629855!5m2!1sen!2sin"
                                className='w-[100vw] md:w-[40vw] h-[450px] border-0' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></a>

                        </div>
                    </div>

                </div>
                <div className='text-white flex items-center justify-center flex-col mt-6 mx-4'>
                            <h1 className='text-orange-700 text-2xl border py-1 px-2 hover:bg-orange-600 cursor-pointer  hover:text-white'>Contact Details</h1>
                            
                            <div className='flex items-center justify-center gap-1 md:text-xl text-orange-500 flex-wrap hover:text-white'><MdOutlineEmail /> <a href="mailto:maasharda_construction@yahoo.in">maasharda_construction@yahoo.in</a></div>
                            <div className='flex items-center justify-center gap-2 text-xl text-orange-500 hover:text-white'><IoCallOutline /><a href="tel: 9411111161">+91 9411111161</a> </div>
                            <div className='flex items-center justify-center gap-2 text-xl text-orange-500 hover:text-white'><IoCallOutline /><a href="tel: 9536133777">+91 9536133777</a> </div>
                        </div>
            </div>
        </>
    )
}

export default Contact