import React from 'react'
import Navbar from './Navbar'
import { MdBusinessCenter } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";


const Career = () => {
    return (
        <>
            <div>
                <Navbar />

                <div className="pt-20 text-white px-3 w-full pb-11 ">
                    <h1 className='text-2xl text-orange-500 flex gap-1 items-center'> <MdBusinessCenter />Latest Jobs</h1>
                    <div className='flex flex-col items-center justify-center w-full'>
                        <div className='flex flex-col items-center justify-center md:flex-row md:flex-wrap pt-4 gap-6'>
                            <div data-aos="fade-up"
                                data-aos-duration="3000" className="px-2 shadow-[rgba(0,_0,0,_1.24)_0px_3px_8px] flex justify-center flex-col items-center py-7 md:w-2/5">
                                <h1>Construction Engineering and Management</h1>
                                <p>FullTime - (0-2 Year's Experience)</p>
                                <p className='flex gap-1 items-center'><CiLocationOn /> India</p>
                                <div> Apply - <span>maasharda_construction@yahoo.co.in</span></div>
                            </div>
                            <div data-aos="fade-up"
                                data-aos-duration="3000" className="px-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px] flex justify-center flex-col items-center py-7 md:w-2/5">
                                <h1>Structural Engineering</h1>
                                <p>FullTime - (0-2 Year's Experience)</p>
                                <p className='flex gap-1 items-center'><CiLocationOn /> India</p>
                                <div> Apply - <span>maasharda_construction@yahoo.co.in</span></div>
                            </div>
                            <div data-aos="fade-up"
                                data-aos-duration="3000" className="px-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  flex justify-center flex-col items-center py-7 md:w-2/5">
                                <h1>Geotechnical Engineering</h1>
                                <p>FullTime - (0-2 Year's Experience)</p>
                                <p className='flex gap-1 items-center'><CiLocationOn /> India</p>
                                <div> Apply - <span>maasharda_construction@yahoo.co.in</span></div>
                            </div>
                            <div data-aos="fade-up"
                                data-aos-duration="3000" className="px-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  flex justify-center flex-col items-center py-7 md:w-2/5">
                                <h1>Transportation Engineering</h1>
                                <p>FullTime - (0-2 Year's Experience)</p>
                                <p className='flex gap-1 items-center'><CiLocationOn /> India</p>
                                <div> Apply - <span>maasharda_construction@yahoo.co.in</span></div>
                            </div>
                            <div data-aos="fade-up"
                                data-aos-duration="3000" className="px-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  flex justify-center flex-col items-center py-7 md:w-2/5">
                                <h1>Environmental  Engineering and Science</h1>
                                <p>FullTime - (0-2 Year's Experience)</p>
                                <p className='flex gap-1 items-center'><CiLocationOn /> India</p>
                                <div> Apply - <span>maasharda_construction@yahoo.co.in</span></div>
                            </div>
                            <div data-aos="fade-up"
                                data-aos-duration="3000" className="px-2 shadow-[rgba(0,_0,_0,_1.24)_0px_3px_8px]  flex justify-center flex-col items-center py-7 md:w-2/5">
                                <h1>Water Resources Engineering</h1>
                                <p>FullTime - (0-2 Year's Experience)</p>
                                <p className='flex gap-1 items-center'><CiLocationOn /> India</p>
                                <div> Apply - <span>maasharda_construction@yahoo.co.in</span></div>
                            </div>
                        </div>
                    </div>


                    {/* <button className='flex right-0 justify-end bg-orange-500 px-2 py-1 text-white rounded mt-6'>See more</button> */}

                </div>


            </div>
        </>
    )
}

export default Career