import React from 'react'
import Banner from './component/Banner'
import NavCard from './component/NavCard'
import About from './component/About'
import Project from './component/Project'
import Contact from './component/Contact'
import Footer from './component/Footer'
import Services from './component/Services'
import Service1 from './component/Service1'
import Career from './component/Career'
import Gallery from './component/Gallery'
import Blog from './component/Blog'
import Navbar from './component/Navbar'
import Projectadd from './component/Projectadd'
// import Testinomals from './component/Testinomals'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <>
        <Banner />
        <NavCard />
        <About />
        <Services />
        <Service1 />
        <Project />
        <Blog/>
        <Contact />
        <Footer />
      </>,
    },
    {
      path: "/career",
      element: <><Career /><Footer/> </>,
    },
    {
      path: "/gallery",
      element: <><Gallery/>  <Footer/> </>,
    },
    {
      path: "/contact",
      element: <><Navbar/> <Contact/>  <Footer/> </>,
    },
    {
      path: "/about",
      element: <><Navbar/> <About/>  <Footer/> </>,
    },
    {
      path: "/projectadd",
      element: <><Navbar/> <Projectadd/>   <Footer/> </>,
    },
  ]);
  return (
    <>


      {/* <Testinomals/> */}

      <RouterProvider router={router} />
    </>
  )
}

export default App