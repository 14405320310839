import React from 'react'
import team from '../img/image/team1.jpg'
import team2 from '../img/image/team2.jpg'
import team3 from '../img/image/team3.jpg'



const NavCard = () => {
  return (
    <>
      <div className='mt-24 mb-20'>
        <div className='flex justify-between gap-2 flex-wrap md:flex-nowrap px-3 pt-28 pb-2'>
          <div data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500" className='md:w-1/3 text-white border px-1 py-1 flex flex-col items-center rounded hover:bg-orange-400 duration-300'>
            <div className='-mt-24 md:mt-0'>
              <img src={team} alt="" />
            </div>
            <div className='text-center'>
              <h1 className='text-xl'>Experience Team</h1>
              <p>Backed by an experienced team of professionals, we provide expertise and guidance to ensure the optimal selection and utilization.</p>
            </div>
          </div>
          <div data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500" className='md:w-1/3 md:mt-0 mt-48 text-white border px-1 py-1 flex flex-col items-center rounded hover:bg-orange-400 duration-300'>
            <div className='-mt-24 md:mt-0'>
              <img src={team2} alt="" />
            </div>
            <div className='text-center'>
              <h1 className='text-xl'>Modern Equipment</h1>
              <p>Backed by an experienced team of professionals, we provide expertise and guidance to ensure the optimal selection and utilization.</p>
            </div>
          </div>
          <div data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500" className='md:w-1/3 md:mt-0 mt-48 text-white border px-1 py-1 flex flex-col items-center rounded hover:bg-orange-400 duration-300'>
            <div className='-mt-24 md:mt-0'>
              <img src={team3} alt="" />
            </div>
            <div className='text-center'>
              <h1 className='text-xl'>Smart Method</h1>
              <p>Employing smart methodologies, we optimize resource utilization and minimize waste throughout our pro duction processes.</p>
            </div>
          </div>
        </div>
      </div>
      {/* <hr /> */}
    </>
  )
}

export default NavCard