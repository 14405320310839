import React from 'react'
import { FaArrowRight } from "react-icons/fa";
import { TiArrowRightOutline } from "react-icons/ti";
import { TiArrowDownOutline } from "react-icons/ti";
import { TiArrowDownThick } from "react-icons/ti";
import sbanner from '../img/service/sbanner.jpg'
import { FaHome } from "react-icons/fa";
import { MdEngineering } from "react-icons/md";
import { RiTeamLine } from "react-icons/ri";
import { IoMdMail } from "react-icons/io";

const Service1 = () => {
    return (
        <>
            <div className=' text-white flex flex-col lg:flex-row w-full pt-16 px-4 lg:gap-20 pb-14' >
                <div className='left w-full'>
                    <div>
                        <img src={sbanner} alt="" />
                        <div data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1500" className='flex  items-center flex-col lg:flex-row gap-5 pb-5'>
                            <span className='bg-orange-500 px-3 py-1 text-xl md:text-sm'>Infrastructue</span> <FaArrowRight className='hidden lg:block text-orange-500 text-xl' /> <TiArrowDownThick className=' lg:hidden text-orange-500 text-xl' /><span className='bg-gray-500 px-3 py-1 text-xl md:text-sm'>Heavy Industrial</span><TiArrowRightOutline className='hidden lg:block text-orange-500 text-xl' /> <TiArrowDownOutline className='lg:hidden text-orange-500 text-xl' /><span className='bg-gray-500 px-3 py-1 text-xl md:text-sm'>Special Project</span>
                        </div>
                    </div>
                    <div className='border-orange-500 border px-1 text-justify'>
                        <p>
                            Civil infrastructure encompasses the physical assets and systems that facilitate societal functions and economic activities, including transportation networks, water supply systems, energy grids, and telecommunications networks. Its development and maintenance are vital for sustaining modern societies and promoting economic growth and social well-being</p>
                    </div>
                </div>
                <div className='righ mt-16 w-full'>
                    <div className='flex items-center text-orange-500 text-2xl py-3'>
                        <FaHome /> Our Best Services
                    </div>
                    <div>
                        <h1 data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1500" className='text-3xl font-bold'>Why Choose Us MSC <span className='text-orange-500 font-bold'>?</span></h1>
                        <p className='pt-2'>With our specialized expertise and tailored solutions, we consistently deliver optimal industrial solutions, ensuring efficiency, reliability, and client satisfaction.</p>
                    </div>

                    <div data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500">
                        <div>
                            <div className='pt-9'>
                                <div className='flex items-center gap-3'>
                                    <div className='flex items-center justify-center border rounded-full border-orange-500 h-28 w-28 border-dotted'> <MdEngineering className='text-orange-500 text-6xl' /></div>
                                    <div className='text-xl'>Experience & Quality</div>
                                </div>
                                <p className='pt-2'>Simple actions make a differenc. It starts and ends with each.</p>
                            </div>
                            <div className='pt-9'>
                                <div className='flex items-center gap-3'>
                                    <div className='flex items-center justify-center border rounded-full border-orange-500 h-28 w-28 border-dotted'> <RiTeamLine className='text-orange-500 text-6xl' /></div>
                                    <div className='text-xl'>Professional Team Works</div>
                                </div>
                                <p className='pt-2'>Professional teamwork thrives on effective communication.</p>
                            </div>
                        </div>
                        <div className='flex pt-11 items-center gap-3'>
                            <div className='flex items-center justify-center  rounded-full  h-14  w-14 bg-orange-500'><IoMdMail className='text-xl text-black' /></div>
                            <span className='text-orange-500 '><p>Mail Us Anytime</p>
                                <p className='text-xs' ><a href="mailto:maasharda_construction@yahoo.co.in">maasharda_construction@yahoo.co.in</a> <br />
                                <a href="mailto:maasharda_construction@yahoo.in">maasharda_construction@yahoo.in</a></p></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Service1